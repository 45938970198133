<template>
  <entity-card
    :tabs="tabs"
    :tabs-items="tabsItems"
    :title="item.name"
    :is-favourite="item.favourite"
    :image="item.pictureUrl"
    fallback-image="/img/object.png"
    :show-favourite-btn="!canBack"
    :show-back-btn="canBack"
    :actions="actions"
    :loading="loading"
    @click:close="handleClose"
    @click:back="handleBack"
    @click:favourite="toggleFavourite(item)"
  >
    <template #header:status-bar>
      <div class="mr-2" :class="{ 'red--text': !item.enabled }">
        {{ item.enabled ? 'Enabled' : 'Disabled' }}
      </div>
      <div class="mr-2 d-flex flex-nowrap">
        <v-icon
          v-if="batteryIcon"
          small
          :color="batteryIcon.color"
          v-text="batteryIcon.icon"
        />
        <div v-if="showBatteryLevel" :style="{ color: batteryIcon.color }">
          {{ item.statusesBatteryLevel.value + '%' }}
        </div>
      </div>
      <v-icon
        v-if="activityIcon"
        :color="activityIcon.color"
        class="mr-2"
        small
        v-text="activityIcon.icon"
      />
      <v-icon
        v-if="alarmIcon"
        :color="alarmIcon.color"
        class="mr-2"
        small
        v-text="alarmIcon.icon"
      />
      <v-icon
        v-if="dataIcon"
        :color="dataIcon.color"
        class="mr-2"
        small
        v-text="dataIcon.icon"
      />
      <v-icon
        v-if="gpsIcon"
        :color="gpsIcon.color"
        class="mr-2"
        small
        v-text="gpsIcon.icon"
      />
      <v-icon
        v-if="item.statusesEmulation && item.statusesEmulation.value"
        class="mr-2"
        small
        v-text="'$emulation'"
      />
      <v-icon v-if="item.muted" class="mr-2" small v-text="'$mute'" />
      <v-icon v-if="item.favourite" class="mr-2" small v-text="'$star_on'" />
    </template>
  </entity-card>
</template>

<script>
import EntityCard from '@/components/entity-card';
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  provide,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';

import { useObjectMenu } from '@/modules/objects/compositions/objectMenu';

import { useMap } from '@/compositions/map';
import { useObject } from '@/modules/objects/compositions/object';
import { objectService } from '../../api';
import { useObjectStatus } from '../../compositions/objectStatus';
import useHistoryStack from '@/compositions/history-stack';

export default {
  name: 'ObjectCard',
  components: {
    EntityCard
  },
  props: {
    objectId: {
      type: [Number, String],
      required: true
    }
  },
  setup(props, { root, emit }) {
    const dragged = ref(null);
    const { hasPrevRoute: canBack } = useHistoryStack();
    const handleBack = () => {
      emit('stack:back');
    };

    onBeforeUnmount(() => {
      unselectMarker(props.objectId);
    });

    const {
      markers: {
        unselectMarker,
        zoomInAndSelectMarker,
        dragMarker,
        undragMarker,
        updateMarker
      }
    } = useMap();

    const { objectId } = toRefs(props);

    const { item, load, loading } = useObject(objectId);

    const tabs = [
      {
        id: 'general',
        title: 'General'
      },
      {
        id: 'linkedObjects',
        title: 'Objects'
      },
      {
        id: 'geo',
        title: 'Geo'
      },
      {
        id: 'media',
        title: 'Media'
      },
      // {
      //   id: 'datasets',
      //   title: 'Datasets'
      // },
      {
        id: 'notifications',
        title: 'Notifications'
      }
      // {
      //   id: 'access',
      //   title: 'Access'
      // }
    ];
    const tabsItems = [
      () => import('./general/index'),
      () => import('./linked-objects/index'),
      () => import('./geo/index'),
      () => import('./media/index'),
      // () => import('./datasets/index'),
      {
        component: () =>
          import('@/modules/notifications/ui/RecentlyNotificationList'),
        props: { objectId: props.objectId }
      }
      // () => import('./access/index')
    ];
    const handleClose = () => {
      // zoomOut(props.objectId);
      unselectMarker(props.objectId);
      emit('stack:close');
    };

    onBeforeMount(async () => {
      // zoomTo(props.objectId);
      await load();
    });

    watch(
      () => item.value,
      item => {
        updateMarker(item);
        // prevent move map layer and set marker to the center if we are in some nested card
        if(!canBack.value) {
          zoomInAndSelectMarker(objectId.value);
        }
      }
    );

    provide('object', item);
    provide('objectId', props.objectId);

    const move = () => {
      if (dragged.value === null) {
        dragged.value = root.$route.params.objectId;
        dragMarker(dragged.value);
      } else {
        dragged.value = null;
        undragMarker();
      }
    };

    const itemActions = computed(() =>
      useObjectMenu({ object: item.value, move })
    );

    const toggleFavourite = ({ id, favourite }) => {
      objectService.setFavourite(id, !favourite);
    };

    const {
      batteryIcon,
      showBatteryLevel,
      gpsIcon,
      alarmIcon,
      activityIcon,
      dataIcon
    } = useObjectStatus(item);

    return {
      tabs,
      actions: itemActions,
      handleClose,
      tabsItems,
      loading,
      item,
      toggleFavourite,
      canBack,
      handleBack,
      batteryIcon,
      showBatteryLevel,
      gpsIcon,
      alarmIcon,
      activityIcon,
      dataIcon
    };
  }
};
</script>

<style lang="scss">
.v-slide-group__next,
.v-slide-group__prev {
  display: none !important;
}
</style>
